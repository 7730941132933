/**
 * A collection of SLO definitions. Each value represents a workflow we track. The values
 * of each constant here represent an ID for the SLO workflow and are used in reporting.
 */
export class SloDefinitions {
  /**
   * @product SupportPortal
   * @category admin-action
   * @priority Secondary
   * @description Admin triggers an eligibility reprocess operation for a set of Nonprofits.
   */
  public static BulkReprocessNonprofitEligibility: string = 'adm-actions-eligibility-bulk-reprocess';

  /**
   * @product SupportPortal
   * @category admin-action
   * @priority Secondary
   * @description Admin triggers an eligibility reprocess operation for a Nonprofit.
   */
  public static ReprocessNonprofitEligibility: string = 'adm-actions-eligibility-reprocess';

  /**
   * @product SupportPortal
   * @category admin-action
   * @priority Secondary
   * @description An escalation is created for a Nonprofit, requiring an admin's attention.
   */
  public static NonprofitEscalation: string = 'adm-actions-escalation';

  /**
   * @product SupportPortal
   * @category admin-action
   * @priority Primary
   * @description Admin links an existing Azure Registration to an existing Nonprofit.
   */
  public static LinkAzureRegistration: string = 'adm-actions-link-azure-registration';

  /**
   * @product SupportPortal
   * @category admin-action
   * @priority Primary
   * @description Admin adds a comment to the nonprofit document.
   */
  public static CommentOnNonprofit: string = 'adm-actions-nonprofit-comment';

  /**
   * @product SupportPortal
   * @category admin-action
   * @priority Primary
   * @description Admin marks the nonprofit(s) as fraud.
   */
  public static MarkNonprofitAsFraud: string = 'adm-actions-set-fraud';

  /**
   * @product SupportPortal
   * @category nonprofit-search
   * @priority Secondary
   * @description Admin performs a bulk search operation for nonprofits.
   */
  public static BulkNonprofitSearch: string = 'adm-admin-search';

  /**
   * @product SupportPortal
   * @category agent-verification
   * @priority Secondary
   * @description Admin views agent verification requests.
   */
  public static ViewAgentVerificationRequests: string = 'adm-agent-verification';

  /**
   * @product SupportPortal
   * @category agent-verification
   * @priority Secondary
   * @description Admin approves an outstanding agent verification request.
   */
  public static ApproveAgentVerificationRequest: string = 'adm-agent-verification-approval';

  /**
   * @product SupportPortal
   * @category agent-verification
   * @priority Secondary
   * @description Admin views an agent verification request's details.
   */
  public static AgentVerificationRequestDetails: string = 'adm-agent-verification-details';

  /**
   * @product SupportPortal
   * @category agent-verification
   * @priority Secondary
   * @description Admin creates an outreach request via RAVE.
   */
  public static RequestClientOutreach: string = 'adm-agent-verification-outreach';

  /**
   * @product SupportPortal
   * @category nonprofit-details
   * @priority Primary
   * @description Admin views a nonprofit's details including profile information, history, and all Nonprofit Documents.
   */
  public static ViewNonprofitDetails: string = 'adm-nonprofit-details';

  /**
   * @product SupportPortal
   * @category nonprofit-search
   * @priority Primary
   * @description Admin performs a search for nonprofits.
   */
  public static SearchNonprofits: string = 'adm-nonprofit-search';

  /**
   * @product SupportPortal
   * @category admin-signin
   * @priority Primary
   * @description Admin signs in to the Nonprofit Support Portal.
   */
  public static SupportSignIn: string = 'adm-signin';

  /**
   * @product SupportPortal
   * @category nonprofit-details
   * @priority Secondary
   * @description Admin views the uploaded registation documents for a nonprofit.
   */
  public static ViewNonprofitSignupDocuments: string = 'adm-signup-document-details';

  /**
   * @product NonprofitPlatform
   * @category meo
   * @priority Primary
   * @description Send an email batch request to MEO.
   */
  public static SendEmailBatchRequest: string = 'cor-email-batch-request';

  /**
   * @product NonprofitPlatform
   * @category meo
   * @priority Primary
   * @description Check the status of an email batch request from MEO.
   */
  public static MonitorEmailBatchStatus: string = 'cor-email-batch-status';

  /**
   * @product NonprofitPlatform
   * @category gdpr-request
   * @priority Secondary
   * @description A user has requested we delete any of their associated data in our system.
   */
  public static DeleteUserInfoGdprRequest: string = 'cor-gdpr-delete';

  /**
   * @product NonprofitPlatform
   * @category gdpr-request
   * @priority Secondary
   * @description A user has requested we export any of their associated data in our system for their review.
   */
  public static ExportUserInfoGdprRequest: string = 'cor-gdpr-export';

  /**
   * @product NonprofitPlatform
   * @category modern-risk
   * @priority Secondary
   * @description Process a Modern Risk update response.
   */
  public static ProcessModernRiskResponse: string = 'cor-modern-risk-response';

  /**
   * @product NonprofitPlatform
   * @category techsoup
   * @priority Primary
   * @description Process a TechSoup Organization Update.
   */
  public static TechSoupOrganizationUpdate: string = 'cor-techsoup-org-update';

  /**
   * @product NonprofitPlatform
   * @category techsoup
   * @priority Primary
   * @description Send a Nonprofit Validation request to TechSoup.
   */
  public static TechSoupValidationRequest: string = 'cor-techsoup-validation-request';

  /**
   * @product NonprofitPlatform
   * @category techsoup
   * @priority Primary
   * @description Process a Nonprofit Validation response from TechSoup.
   */
  public static TechSoupValidationResponse: string = 'cor-techsoup-validation-response';

  /**
   * @product NonprofitPortal
   * @category nonprofit-decline
   * @priority Secondary
   * @description A background process which declines unapproved nonprofits who are past their approval date.
   */
  public static DeclineUnapprovedNonprofits: string = 'npp-decline-nonprofit';

  /**
   * @product NonprofitPortal
   * @category nonprofit-engineering
   * @priority Secondary
   * @description Internal administrative tools for Nonprofit Engineering.
   */
  public static NonprofitDevelopmentTools: string = 'npp-dev-tools';

  /**
   * @product NonprofitPortal
   * @category nonprofit-home
   * @priority Primary
   * @description Nonprofit user lands on the home page.
   */
  public static NonprofitHomePage: string = 'npp-home';

  /**
   * @product NonprofitPortal
   * @category nonprofit-notifications
   * @priority Secondary
   * @description The nonprofit system sends an action needed notification to the Nonprofit user.
   */
  public static NotificationsActionNeeded: string = 'npp-notifications-action-needed';

  /**
   * @product NonprofitPortal
   * @category nonprofit-notifications
   * @priority Secondary
   * @description The nonprofit system sends an azure credit renewal notification to the Nonprofit user.
   */
  public static NotificationsAzureCreditRenew: string = 'npp-notifications-azure-credit-renew';

  /**
   * @product NonprofitPortal
   * @category nonprofit-notifications
   * @priority Secondary
   * @description The nonprofit system sends a declined notification to the Nonprofit user.
   */
  public static NotificationsDeclined: string = 'npp-notifications-declined';

  /**
   * @product NonprofitPortal
   * @category nonprofit-notifications
   * @priority Secondary
   * @description The nonprofit system sends a reminder of approval to inactive nonprofits so they complete offer conversion.
   */
  public static NotificationsInactiveApprovals: string = 'npp-notifications-inactive-approvals';

  /**
   * @product NonprofitPortal
   * @category nonprofit-offers
   * @priority Primary
   * @description Nonprofit user accepts the Account Guard offer.
   */
  public static NonprofitOfferAccountGuard: string = 'npp-offers-account-guard';

  /**
   * @product NonprofitPortal
   * @category nonprofit-offers
   * @priority Primary
   * @description Nonprofit user accepts the Azure Credit offer.
   */
  public static NonprofitOfferAzureCredit: string = 'npp-offers-azure-credit';

  /**
   * @product NonprofitPortal
   * @category nonprofit-offers
   * @priority Primary
   * @description Nonprofit user renews their Azure Credit offer.
   */
  public static NonprofitOfferAzureCreditRenewal: string = 'npp-offers-azure-credit-renew';

  /**
   * @product NonprofitPortal
   * @category nonprofit-offers
   * @priority Primary
   * @description Nonprofit user accepts the Bing Ads offer.
   */
  public static NonprofitOfferBingAds: string = 'npp-offers-bing-ads';

  /**
   * @product NonprofitPortal
   * @category nonprofit-offers
   * @priority Primary
   * @description Nonprofit user accepts the Microsoft Cloud for Nonprofits offer.
   */
  public static NonprofitOfferCloud: string = 'npp-offers-cloud';

  /**
   * @product NonprofitPortal
   * @category nonprofit-offers
   * @priority Primary
   * @description Nonprofit user accepts the D365 offer.
   */
  public static NonprofitOfferD365: string = 'npp-offers-d365';

  /**
   * @product NonprofitPortal
   * @category nonprofit-offers
   * @priority Primary
   * @description Nonprofit user accepts the GitHub offer.
   */
  public static NonprofitOfferGitHub: string = 'npp-offers-github';

  /**
   * @product NonprofitPortal
   * @category nonprofit-offers
   * @priority Primary
   * @description Nonprofit user submits a Microsoft Surface hardware request.
   */
  public static NonprofitOfferHardwareRequest: string = 'npp-offers-hardware';

  /**
   * @product NonprofitPortal
   * @category nonprofit-offers
   * @priority Primary
   * @description Nonprofit user accepts the LinkedIn offer.
   */
  public static NonprofitOfferLinkedIn: string = 'npp-offers-linkedin';

  /**
   * @product NonprofitPortal
   * @category nonprofit-offers
   * @priority Primary
   * @description Nonprofit user accepts the M365 offer.
   */
  public static NonprofitOfferM365: string = 'npp-offers-m365';

  /**
   * @product NonprofitPortal
   * @category nonprofit-offers
   * @priority Primary
   * @description Nonprofit user wishes to engage with a Microsoft Partner.
   */
  public static NonprofitOfferMicrosoftPartners: string = 'npp-offers-partner';

  /**
   * @product NonprofitPortal
   * @category nonprofit-offers
   * @priority Primary
   * @description Nonprofit user accepts the Software offer.
   */
  public static NonprofitOfferSoftware: string = 'npp-offers-software';

  /**
   * @product NonprofitPortal
   * @category nonprofit-offers
   * @priority Primary
   * @description Nonprofit user engages with the Training content.
   */
  public static NonprofitOfferTraining: string = 'npp-offers-training';

  /**
   * @product NonprofitPortal
   * @category nonprofit-profile
   * @priority Primary
   * @description Nonprofit user view their Nonprofit profile.
   */
  public static NonprofitProfileDetails: string = 'npp-profile-details';

  /**
   * @product NonprofitPortal
   * @category nonprofit-profile
   * @priority Primary
   * @description Nonprofit user updates their Nonprofit profile.
   */
  public static NonprofitProfileUpdate: string = 'npp-profile-update';

  /**
   * @product NonprofitPortal
   * @category nonprofit-registration
   * @priority Primary
   * @description An existing Microsoft customer creates a nonprofit registration application via the Nonprofit Portal.
   */
  public static ExistingTenantRegistration: string = 'npp-register-existing-tenant';

  /**
   * @product NonprofitPortal
   * @category nonprofit-registration
   * @priority Primary
   * @description A new Microsoft customer creates a nonprofit registration application via the Nonprofit Portal.
   */
  public static NewTenantRegistration: string = 'npp-register-new-tenant';

  /**
   * @product NonprofitPortal
   * @category nonprofit-signin
   * @priority Primary
   * @description Tenant admin signs in to the Nonprofit Portal after Nonprofit registration has been completed.
   */
  public static NonprofitSignIn: string = 'npp-signin';

  /**
   * @product NonprofitPortal
   * @category nonprofit-support
   * @priority Primary
   * @description Nonprofit user submits a customer support request.
   */
  public static NonprofitCustomerSupport: string = 'npp-support-request';

  /**
   * @product NonprofitPortal
   * @category nonprofit-profile
   * @priority Secondary
   * @description The nonprofit user verifies their email address to complete their profile creation or updates.
   */
  public static VerifyNonprofitAdminEmailAddress: string = 'npp-verify-email-address';
}
